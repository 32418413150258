<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	computed: {
		client() {
			return this.clientById(Number(this.$route.params.id))
		},
		...mapGetters({
			clientById: 'arceClients/getById'
		})
	},
	methods: {
		initAnalisys() {
			this.initWizard()
		},
		initWizard() {			
			this.initArceWizard({
				data: {
					start: true,
					client: {...this.client},
				},
				steps: [
					{
						sort: 1,
						key: 'start',
						status: 'current'
					},
				]
			})
			

			this.showArceWizard()

			this.ratingsAdd({...this.client})
		},
		...mapMutations({
			ratingsAdd: 'arceRatings/add',
			showArceWizard: 'arceStatus/showArceWizard',
			initArceWizard: 'arceStatus/initArceWizard',
            arceRatingsSetRating: 'arceRatings/setRating'
		}),
	}
}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1 s-single">
		<div class="col-12 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header bg-light p-3">
							<div class="d-flex align-items-center">
								<div class="s-element__icon">
									<img src="@/assets/simulator/account_120.png" alt="">
								</div>
								<div>
									<p class="m-0">Client</p>
									<h3 class="title">{{client.nombre}} {{client.bbvaid}}</h3>
								</div>
								<div class="ml-auto d-flex">
									<button class="s-btn s-btn--outline s-btn-primary text-primary">
										<SvgIcon name="add"/>
										Follow
									</button>
									<div class="ml-3 s-btn-group">
										<button class="s-btn s-btn--outline s-btn-primary text-primary">
											New Opportunity
										</button>
										<button class="s-btn s-btn--outline s-btn-primary text-primary">
											Plan Visit/Call
										</button>
										<button class="s-btn s-btn--outline s-btn-primary text-primary" @click="initAnalisys">
											ARCE
										</button>
										<button class="s-btn s-btn--outline s-btn-primary text-primary text-lg">
											<SvgIcon name="down"></SvgIcon>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="s-panel__body p-3">
							<div class="d-flex">
								<div class="col-1">
									<p class="m-0 text-muted">Client type</p>
									<p class="m-0 ">{{ client.tipo }}</p>
								</div>
								<div class="col-1">
									<p class="m-0 text-muted">Parent</p>
									<p class="m-0 text-muted"><input type="checkbox" name="" id=""></p>
								</div>
								<div class="col-1">
									<p class="m-0 text-muted">Country</p>
									<p class="m-0">{{ client.pais_cabecera }}</p>
								</div>
								<div class="col-1">
									<p class="m-0 text-muted">INTERNAL RATING</p>
								</div>
								<div class="col-1">
									<p class="m-0 text-muted">ID Fiscal</p>
									<p class="m-0">{{ client.id_fiscal || 'Y050748855' }}</p>
								</div>
								
								
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-8 col-xl-9 p-0">
			<div class="d-flex flex-wrap">
				<div class="col-12 px-2 py-2">
					<div class="s-panel2 h-100">
						<div class="s-panel__body p-3 position-relative h-100 d-flex">
							<div class="col-3 p-1 ">
								<div class="border rounded p-2">
									<h3 class="title m-0">BBVA Business</h3>
									<p class="text-muted m-0">GM YTD</p>
									<p class="text-muted m-0">3.262,34 EUR</p>
									<p class="text-muted m-0">GM YoY</p>
									<p class="m-0 text-right">0 %</p>
									<hr>
									<p class="text-muted m-0">GM YTD</p>
									<p class="text-muted m-0">3.262,34 EUR</p>
									<p class="text-muted m-0">GM YoY</p>
									<p class="m-0 text-right">-50,73 %</p>
									<hr>
								</div>
							</div>
							<div class="col-3 p-1 ">
								<div class="border rounded p-2">
									<h3 class="title m-0">Commercial Acti...</h3>
									<p class="text-muted m-0">GM YTD</p>
									<p class="text-muted m-0">3.262,34 EUR</p>
									<p class="text-muted m-0">GM YoY</p>
									<p class="m-0 text-right">0 %</p>
									<hr>
									
								</div>
							</div>
							<div class="col-3 p-1 ">
								<div class="border rounded p-2">
									<h3 class="title m-0">Top 3 Geographies</h3>
									<p class="text-muted m-0">GM YTD</p>
									<p class="text-muted m-0">3.262,34 EUR</p>
									<p class="text-muted m-0">GM YoY</p>
									<p class="m-0 text-right">0 %</p>
									<hr>
									<p class="text-muted m-0">GM YTD</p>
									<p class="text-muted m-0">3.262,34 EUR</p>
									<p class="text-muted m-0">GM YoY</p>
									<p class="m-0 text-right">-50,73 %</p>
									<hr>
								</div>
							</div>
							<div class="col-3 p-1 ">
								<div class="border rounded p-2">
									<h3 class="title m-0">Client Info</h3>
									<p class="text-muted m-0">GM YTD</p>
									<p class="text-muted m-0">3.262,34 EUR</p>
									<p class="text-muted m-0">GM YoY</p>
									<p class="m-0 text-right">0 %</p>
									<hr>
									<p class="text-muted m-0">GM YTD</p>
									<p class="text-muted m-0">3.262,34 EUR</p>
									<p class="text-muted m-0">GM YoY</p>
									<p class="m-0 text-right">-50,73 %</p>
									<hr>
								</div>
							</div>
							
							
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-4 col-xl-3 p-0">
			<div class="d-flex flex-wrap">
				<div class="col-12 px-2 py-2">
					<div class="s-collapse__header py-3 border" data-toggle="collapse" data-target="#aside1" aria-expanded="true" aria-controls="aside1">
						<div class="d-flex">
							<p class="font-weight-bold m-0">
								<span class="s-element__icon s-element__icon--24 s-element__icon--purple">
									<img src="@/assets/simulator/account_120.png" alt="">
								</span>
							</p>
							<span>Analytic Reports</span>

						</div>
						<div class="text-center">

							<p class="text-primary m-0">
								GTB Dynamic Pricing - client
							</p>
							<p class="text-primary m-0">
								Global Solutions dashboards
							</p>
							<p class="text-primary m-0">
								Commercial Proactivity CrossBoder Dashboard
							</p>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-collapse__header py-3 border" data-toggle="collapse" data-target="#aside1" aria-expanded="true" aria-controls="aside1">
						<div class="d-flex">
							<p class="font-weight-bold m-0">
								<span class="s-element__icon s-element__icon--24 s-element__icon--purple">
									<img src="@/assets/simulator/account_120.png" alt="">
								</span>
							</p>
							<span>News</span>

						</div>
						<div class="text-center">

							<p class="text-primary m-0">
								GTB Dynamic Pricing - client
							</p>
							<p class="text-primary m-0">
								Global Solutions dashboards
							</p>
							<p class="text-primary m-0">
								Commercial Proactivity CrossBoder Dashboard
							</p>
						</div>
					</div>
				</div>
				
			</div>
		</div>	
		
	</div>
</template>